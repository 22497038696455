<script lang="ts">
    import BadgesList from '@coursesoverviewtype_combolist/components/BadgesList.svelte'

    export let item: TCourse

    let showProgress = !item.iscomplete
        && item.enablecompletion
        && Number.isFinite(item.completionprogress)

    let badges: Map<string|number, TBadge> = new Map()
    $: badges = new Map(item.badges?.map(b => [ b.name, b ]))

    $: {
        if (item.iscomplete) {
            badges = badges.set('complete', {
                label: 'Abgeschlossen',
                icon: 'check',
                name: 'complete',
                type: 'primary',
            })
        }
    }

</script>

<a href={item.link} class="item item--course" class:item--hidden={!item.visible}>

    {#if item.previewurl}
        <div class="item-header" style={`background-image: url('${item.previewurl}');`}>
            <BadgesList badges={badges} />
        </div>
    {:else}
        <div class="item-header">
            <svg viewBox="0 0 24 24">
                <use href={`${window.M.util.image_url('icons', 'local_coursesoverview')}#course`} />
            </svg>

            <BadgesList badges={badges} />
        </div>
    {/if}

    <div class="item-content">

        {#if item.fullname}
            <h4 class="item-name">{@html item.fullname}</h4>
        {/if}

        {#if item.prehtml}
            <div class="item-prehtml">
                {@html item.prehtml}
            </div>
        {/if}

        {#if item.summary}
            <p class="item-description">{@html item.summary}</p>
        {/if}

        {#if item.posthtml}
            <div class="item-posthtml">
                {@html item.posthtml}
            </div>
        {/if}
    </div>

    <footer class="item-footer">
        {#if item.footerhtml}
            <div class="item-footerhtml">
                {@html item.footerhtml}
            </div>
        {/if}


        {#if item.lastaccesstime}
            <div class="item-lastaccesstime">
                <p>{item.lastaccesstime}</p>
            </div>
        {/if}

        {#if showProgress}
            <div class="item-completionprogress">
                <div class="progress rounded" style="height: 10px">
                    <div class="progress-bar" style={`width: ${item.completionprogress}%`} role="progressbar" aria-valuenow={parseInt(`${item.completionprogress}`, 10)} aria-valuemin={0} aria-valuemax={100}></div>
                </div>

                <span class="small">
                    {parseInt(`${item.completionprogress}`, 10) || 0}% abgeschlossen
                </span>
            </div>
        {/if}

        {#if item.iscomplete && item.enablecompletion}
            <span class="item-completed">
                <svg viewBox="0 0 24 24">
                    <use href={`${window.M.util.image_url('icons', 'local_coursesoverview')}#check-circle`} />
                </svg>
            </span>
        {/if}

        {#if item.link}
            <span class="item-btn-goto">
                <svg viewBox="0 0 24 24">
                    <use href={`${window.M.util.image_url('icons', 'local_coursesoverview')}#goto`} />
                </svg>
            </span>
        {/if}

    </footer>
</a>
